<template>
  <svg
    v-if="type === 'default'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 21C7.34711 21.0024 4.80218 19.9496 2.9263 18.0737C1.05042 16.1978 -0.00238272 13.6529 4.04939e-06 11V10.8C0.0817921 6.79223 2.5478 3.22016 6.26637 1.72307C9.98493 0.225973 14.2381 1.0929 17.074 3.92601C19.9365 6.78609 20.7932 11.0893 19.2443 14.8276C17.6955 18.5659 14.0465 21.0024 10 21ZM10 12.41L12.59 15L14 13.59L11.41 11L14 8.41001L12.59 7.00001L10 9.59001L7.41001 7.00001L6.00001 8.41001L8.59001 11L6.00001 13.59L7.41001 15L10 12.411V12.41Z" :fill="fill" />
  </svg>

  <svg
    v-else-if="type === 'outline'"
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 21.0001C6.95355 21.0024 3.30449 18.5659 1.75566 14.8276C0.206833 11.0893 1.06354 6.78609 3.926 3.92601C6.45329 1.39871 10.1369 0.411692 13.5893 1.33675C17.0416 2.2618 19.7382 4.9584 20.6633 8.41075C21.5883 11.8631 20.6013 15.5467 18.074 18.074C16.2014 19.955 13.6542 21.0087 11 21.0001ZM3 11.172C3.04731 15.5733 6.6411 19.1096 11.0425 19.086C15.444 19.0622 18.9995 15.4875 18.9995 11.086C18.9995 6.68452 15.444 3.10978 11.0425 3.08601C6.6411 3.06247 3.04731 6.59877 3 11V11.172ZM8.409 15L7 13.59L9.59 11L7 8.41001L8.41 7.00001L11 9.59001L13.59 7.00001L15 8.41001L12.41 11L15 13.59L13.591 15L11 12.41L8.41 15H8.409Z" :fill="fill" />
  </svg>

  <svg
    v-else-if="type === 'rounded-x'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1.5C4.41594 1.5 1.5 4.41594 1.5 8C1.5 11.5841 4.41594 14.5 8 14.5C11.5841 14.5 14.5 11.5841 14.5 8C14.5 4.41594 11.5841 1.5 8 1.5ZM10.3534 9.64656C10.4018 9.69253 10.4405 9.74772 10.4672 9.80888C10.494 9.87003 10.5082 9.93592 10.509 10.0027C10.5099 10.0694 10.4974 10.1356 10.4722 10.1974C10.4471 10.2593 10.4098 10.3154 10.3626 10.3626C10.3154 10.4098 10.2593 10.4471 10.1974 10.4722C10.1356 10.4974 10.0694 10.5099 10.0027 10.509C9.93592 10.5082 9.87003 10.494 9.80888 10.4672C9.74772 10.4405 9.69253 10.4018 9.64656 10.3534L8 8.70719L6.35344 10.3534C6.25891 10.4432 6.13303 10.4926 6.00265 10.4909C5.87227 10.4892 5.7477 10.4367 5.6555 10.3445C5.5633 10.2523 5.51076 10.1277 5.50909 9.99735C5.50742 9.86697 5.55675 9.74109 5.64656 9.64656L7.29281 8L5.64656 6.35344C5.55675 6.25891 5.50742 6.13303 5.50909 6.00265C5.51076 5.87227 5.5633 5.7477 5.6555 5.6555C5.7477 5.5633 5.87227 5.51076 6.00265 5.50909C6.13303 5.50742 6.25891 5.55675 6.35344 5.64656L8 7.29281L9.64656 5.64656C9.74109 5.55675 9.86697 5.50742 9.99735 5.50909C10.1277 5.51076 10.2523 5.5633 10.3445 5.6555C10.4367 5.7477 10.4892 5.87227 10.4909 6.00265C10.4926 6.13303 10.4432 6.25891 10.3534 6.35344L8.70719 8L10.3534 9.64656Z" :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: 'IconsError',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'outline', 'rounded-x'].includes(value)
    },

    fill: {
      type: String,
      default: '#193560'
    }
  }
}
</script>
